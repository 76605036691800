import { Stack } from "@fluentui/react";
import { HomeIcon } from "@fluentui/react-icons-mdl2";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { appConstants } from "src/appConstants";
import { GenerateReport } from "src/core/components/GenerateReport";
import Page from "src/core/components/Page/Page";
import PageContent from "src/core/components/PageContent";
import { PageHeader, rightIconStyle } from "src/core/components/PageHeader";
import Skeleton from "src/core/components/Skeleton";
import { useAuth } from "src/core/hooks/useAuth";
import { mainAppState } from "src/recoil/atoms";

export const SelectBrandReport = () => {
  const [appState, setAppState] = useRecoilState(mainAppState);

  const [loading, setLoading] = useState(true);

  const { accessToken, success } = useAuth();

  const { t } = useTranslation();
  let navigate = useNavigate();

  useEffect(() => {
    (async () => {
      if (success) {
        if (appState.selectedBrand) {
          setLoading(false);
        } else {
          navigate("/");
        }
      }
    })();
  }, [accessToken, appState.selectedBrand, navigate, success]);

  return (
    <Page>
      <Skeleton loading={loading}>
        <PageHeader
          header={appState.selectedBrand?.name ?? ""}
          onBackClick={() => {
            navigate("/brands-catalogue");
          }}
          rightIcon={
            <HomeIcon
              style={rightIconStyle}
              onClick={() => {
                navigate(`/`);
              }}
            />
          }
        />
        <PageContent>
          <GenerateReport
            title={t("resources.selectReportTitle")}
            brandCode={appState.selectedBrand?.code}
          />
          <Stack
            style={{
              position: "fixed",
              bottom: 0,
              background: "white",
              padding: 0,
              zIndex: 99,
              width: "100%",
              textAlign: "center",
              color: "black",
            }}
          >
            <p>{appConstants()?.lastUpdateDate}</p>
          </Stack>
          <Stack
            style={{
              position: "fixed",
              bottom: 0,
              background: "white",
              padding: 0,
              zIndex: 99,
              width: "100%",
              textAlign: "center",
              color: "black",
            }}
          >
            <p>{appConstants()?.lastUpdateDate}</p>
          </Stack>
        </PageContent>
      </Skeleton>
    </Page>
  );
};
