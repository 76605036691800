import { Image, Space } from "antd";
import { Content } from "antd/lib/layout/layout";
import "src/GeneratorApp.css";
import { CollectionModelV2 } from "src/types/common";

type Props = {
  index: number;
  model: CollectionModelV2;
  getModelImageUrl: (model: CollectionModelV2) => string;
};

export const ModelTableLine = (props: Props) => {
  const { model, index, getModelImageUrl } = props;

  return (
    <Content
      key={`model-color-${model.modelNumber}-${model.modelColor}-${index}`}
      style={{
        maxWidth: "900px",
        margin: "0 auto",
        textAlign: "left",
      }}
    >
      <Space
        direction="horizontal"
        style={{
          borderBottom: "1px solid",
          width: "100%",
        }}
      >
        <Space
          style={{
            minWidth: "450px",
            height: 48,
          }}
        >
          <span
            style={{
              display: "block",
              minWidth: "15px",
            }}
          >
            {index + 1}.
          </span>
          <Image
            src={getModelImageUrl(model)}
            preview={false}
            width={90}
            style={{ maxHeight: 45 }}
          />{" "}
          <b>{model.modelNumber}</b> | {model.modelColor} | {model.macroRange}
        </Space>
        <Space>Orders: {model.soldUnits}</Space>
      </Space>
    </Content>
  );
};
