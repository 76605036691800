import { API_BASE_URL } from "src/constants";
import { get, getBlob, put } from "src/core/utils/api";
import { User } from "src/types/common";

const createUserService = () => {
  const fetchUser = async (accessToken: string): Promise<User> => {
    const response = await get<User>(accessToken, API_BASE_URL + `/users/me`);

    return response;
  };

  const updateUser = async (accessToken: string, data: User) => {
    await put<{}>(accessToken, API_BASE_URL + `/users/me`, data);
  };

  const fetchUserImage = async (accessToken: string): Promise<Blob | null> => {
    try {
      const response = await getBlob<Blob>(
        accessToken,
        "https://graph.microsoft.com/v1.0/me/photo/$value"
      );

      return response;
    } catch {
      return null;
    }
  };

  return {
    fetchUser,
    updateUser,
    fetchUserImage,
  };
};
export const userService = createUserService();
