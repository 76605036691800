import { Col, Layout, Row, Skeleton } from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { dataService } from "src/core/services/dataService";
import { getModelImageUrl } from "src/core/utils/model";
import "src/GeneratorApp.css";
import { ModelTotalsHeader } from "src/pages/HtmlGenerator/components/ModelTotalsHeader";
import { ModelTotalsTableLine } from "src/pages/HtmlGenerator/components/ModelTotalsTableLine";
import { CollectionModelGroupV2, CollectionModelV2 } from "src/types/common";

export const Html3Page = () => {
  const [loading, setLoading] = useState(true);

  const [brandCodeFilter, setBrandCodeFilter] = useState<string>("");
  const [modelTypeFilter, setModelTypeFilter] = useState<string>("");
  const [macroRangeFilter, setMacroRangeFilter] = useState<string>("");

  const [collectionModelTotalGroups, setCollectionModelTotalGroups] = useState<
    CollectionModelGroupV2[]
  >([]);

  const { search } = useLocation();

  useEffect(() => {
    (async () => {
      if (true) {
        if (search) {
          if (true) {
            const query = new URLSearchParams(search);

            const brandCode = query.get("b") ?? "";
            const modelType = query.get("t") ?? "";
            const macroRange = query.get("m") ?? "";

            const dataKey = query.get("d") ?? "";

            setBrandCodeFilter(brandCode);
            setModelTypeFilter(modelType);
            setMacroRangeFilter(macroRange);

            const response =
              await dataService.fetchBrandCollectionModelsGrouped(
                dataKey,
                "ModelAndCollectionType",
                brandCode,
                modelType,
                macroRange
              );

            if (response.length > 0) {
              setCollectionModelTotalGroups(
                response as CollectionModelGroupV2[]
              );
            }

            setLoading(false);
          }
        }
      }
    })();
  }, [search]);

  let currentModelsCount = 0;

  const checkPageBreak = (doPageBreak: boolean = false) => {
    if (currentModelsCount >= 18 || doPageBreak) {
      currentModelsCount = 0;

      return true;
    }

    return false;
  };

  return (
    <Layout
      className={"menuBackground-0"}
      style={{
        textAlign: "center",
        minHeight: "100%",
      }}
    >
      <Skeleton active loading={loading}>
        <Content
          style={{
            margin: "0 auto",
            width: "100%",
          }}
        >
          <Row justify={"center"}>
            <Col span={24}>
              {collectionModelTotalGroups.map(
                (totalsGroup: CollectionModelGroupV2, index) => {
                  return (
                    <Content
                      key={`model-totals-${index}`}
                      style={{
                        textAlign: "center",
                        padding: "20px",
                        paddingTop: 0,
                        maxWidth: 600,
                        margin: "0 auto",
                      }}
                    >
                      {checkPageBreak(true) && (
                        <div className="pagebreak"> </div>
                      )}
                      <ModelTotalsHeader
                        title={`${totalsGroup.brandName} | ${totalsGroup.modelType} | ${totalsGroup.modelCollectionType}`}
                        brandName={totalsGroup.brandName}
                        modelType={totalsGroup.modelType}
                        totalOrders={totalsGroup.totalSoldUnits}
                        currentIndex={1}
                        total={totalsGroup.models.length}
                        showInfoBlock={true}
                        infoBlock={totalsGroup.lastUpdateDateLabel}
                      />
                      <Content
                        key={`model-colors`}
                        style={{
                          maxWidth: "900px",
                          margin: "0 auto",
                          textAlign: "left",
                        }}
                      >
                        {totalsGroup.models.map(
                          (model: CollectionModelV2, index) => {
                            currentModelsCount++;

                            return (
                              <Content
                                key={`model-color-${model.modelNumber}-${model.modelColor}-${index}`}
                                style={{
                                  maxWidth: "900px",
                                  margin: "0 auto",
                                  textAlign: "left",
                                }}
                              >
                                {checkPageBreak() && (
                                  <React.Fragment>
                                    <div className="pagebreak"> </div>
                                    <ModelTotalsHeader
                                      title={`${totalsGroup.brandName} | ${totalsGroup.modelType} | ${totalsGroup.modelCollectionType}`}
                                      brandName={totalsGroup.brandName}
                                      modelType={totalsGroup.modelType}
                                      totalOrders={totalsGroup.totalSoldUnits}
                                      currentIndex={1}
                                      total={totalsGroup.models.length}
                                      showInfoBlock={true}
                                      infoBlock={
                                        totalsGroup.lastUpdateDateLabel
                                      }
                                    />
                                  </React.Fragment>
                                )}
                                <ModelTotalsTableLine
                                  index={index}
                                  model={model}
                                  getModelImageUrl={getModelImageUrl}
                                  lineSubContent={`${model.newOrAddLabel} | ${model.macroRange}`}
                                />
                              </Content>
                            );
                          }
                        )}
                      </Content>
                    </Content>
                  );
                }
              )}
            </Col>
            {collectionModelTotalGroups.length === 0 && (
              <h2 style={{ marginTop: "35px" }}>No orders found.</h2>
            )}
          </Row>
        </Content>
      </Skeleton>
    </Layout>
  );
};
