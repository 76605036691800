export const primaryButtonStyles = {
  root: {
    minWidth: 220,
  },
};

export const primaryDarkButtonStyles = {
  root: {
    ...primaryButtonStyles.root,
    background: "black",
    border: "black",
    height: 45,
    width: 240,
  },
  rootHovered: {
    backgroundColor: "grey",
    border: "grey",
  },
};

export const dropdownStyles = { dropdown: { width: 300 } };
