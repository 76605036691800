import { Stack, Text } from "@fluentui/react";
import { HomeIcon } from "@fluentui/react-icons-mdl2";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { appConstants } from "src/appConstants";
import { BrandsList } from "src/core/components/BrandsList";
import Page from "src/core/components/Page/Page";
import PageContent from "src/core/components/PageContent";
import { PageHeader, rightIconStyle } from "src/core/components/PageHeader";
import Skeleton from "src/core/components/Skeleton";
import { useAuth } from "src/core/hooks/useAuth";
import { brandService } from "src/core/services/brandService";
import { mainAppState } from "src/recoil/atoms";
import { Brand } from "src/types/common";

export const BrandsCatalogue = () => {
  const [appState, setAppState] = useRecoilState(mainAppState);

  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState<string | undefined>();

  const [brandsList, setBrandsList] = useState<Brand[]>([]);

  const { accessToken, success, authLoaded } = useAuth();
  const { t } = useTranslation();
  let navigate = useNavigate();

  useEffect(() => {
    (async () => {
      if (authLoaded) {
        if (success) {
          const response = await brandService.fetchBrands(accessToken);

          setBrandsList(response);
          setLoading(false);
        }
      }
    })();
  }, [accessToken, authLoaded, success]);

  return (
    <Page>
      <Skeleton loading={loading}>
        <PageHeader
          header={t(`resources.brandCatalogue`)}
          setSearchValue={setSearchValue}
          alwaysShowSearch={brandsList.length > 0 ? true : undefined}
          onBackClick={() => {
            navigate(`/`);
          }}
          rightIcon={
            <HomeIcon
              style={rightIconStyle}
              onClick={() => {
                navigate(`/`);
              }}
            />
          }
        />
        <PageContent paddingTop={100}>
          {brandsList && (
            <React.Fragment>
              {brandsList.length > 0 && (
                <BrandsList
                  brands={brandsList}
                  onClick={(brand: Brand) => {
                    setAppState({ ...appState, selectedBrand: brand });
                    navigate(`/select-brand-report`);
                  }}
                  filterValue={searchValue}
                />
              )}
              {brandsList.length === 0 && (
                <Text as="h3">{t(`messages.noBrands`)}</Text>
              )}
            </React.Fragment>
          )}
          <Stack
            style={{
              position: "fixed",
              bottom: 0,
              background: "white",
              padding: 0,
              zIndex: 99,
              width: "100%",
              textAlign: "center",
              color: "black",
            }}
          >
            <p>{appConstants()?.lastUpdateDate}</p>
          </Stack>
        </PageContent>
      </Skeleton>
    </Page>
  );
};
