import { Image, Space } from "antd";
import "src/GeneratorApp.css";
import { CollectionModelV2 } from "src/types/common";

type Props = {
  index: number;
  model: CollectionModelV2;
  lineSubContent: string;
  getModelImageUrl: (model: CollectionModelV2) => string;
};

export const ModelTotalsTableLine = (props: Props) => {
  const { model, index, getModelImageUrl, lineSubContent } = props;

  return (
    <Space
      direction="horizontal"
      style={{
        borderBottom: "1px solid",
        width: "100%",
      }}
    >
      <Space
        style={{
          minWidth: "450px",
          height: 48,
        }}
      >
        <span
          style={{
            display: "block",
            minWidth: "15px",
          }}
        >
          {index + 1}.
        </span>
        <Image
          src={getModelImageUrl(model)}
          preview={false}
          width={90}
          style={{ maxHeight: 45 }}
        />{" "}
        <span
          style={{
            display: "inline-block",
            minWidth: "75px",
          }}
        >
          <b>{model.modelNumber}</b>
        </span>{" "}
        <span style={{ fontSize: "12px" }}>{lineSubContent}</span>
      </Space>
      <Space>Orders: {model.soldUnits}</Space>
    </Space>
  );
};
