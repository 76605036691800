import { API_BASE_URL } from "src/constants";
import { getBlob } from "src/core/utils/api";
import { PdfType } from "src/types/enums";

const createPdfService = () => {
  const fetchAndDownloadBrandPdf = async (
    accessToken: string,
    pdfType: PdfType,
    brandCode: string,
    modelType: string,
    macroRange: string
  ): Promise<boolean> => {
    try {
      const response = await getBlob<Blob>(
        accessToken,
        API_BASE_URL +
          `/pdfs/${pdfType}/brands/${brandCode}?modelType=${modelType}&macroRange=${macroRange}`
      );

      const fileURL = window.URL.createObjectURL(response);

      window.open(fileURL, "_blank");
      return true;
    } catch {
      return false;
    }
  };

  const fetchAndDownloadPdf = async (
    accessToken: string,
    pdfType: PdfType,
    modelType: string,
    macroRange: string
  ): Promise<boolean> => {
    try {
      const response = await getBlob<Blob>(
        accessToken,
        API_BASE_URL +
          `/pdfs/${pdfType}?modelType=${modelType}&macroRange=${macroRange}`
      );

      const fileURL = window.URL.createObjectURL(response);

      window.open(fileURL, "_blank");

      /*let alink = document.createElement("a");
      alink.href = fileURL;
      alink.download = "pdf-file.pdf";

      alink.click();*/

      return true;
    } catch {
      return false;
    }
  };

  return {
    fetchAndDownloadBrandPdf,
    fetchAndDownloadPdf,
  };
};
export const pdfService = createPdfService();
