import { useMsal } from "@azure/msal-react";
import { Stack } from "@fluentui/react";
import { HomeIcon } from "@fluentui/react-icons-mdl2";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { appConstants } from "src/appConstants";
import { GenerateReport } from "src/core/components/GenerateReport";
import Page from "src/core/components/Page/Page";
import PageContent from "src/core/components/PageContent";
import { PageHeader, rightIconStyle } from "src/core/components/PageHeader";
import Skeleton from "src/core/components/Skeleton";
import { useAuth } from "src/core/hooks/useAuth";
import { mainAppState } from "src/recoil/atoms";
import { FilterType } from "src/types/enums";

export const SelectReport = () => {
  const [appState, setAppState] = useRecoilState(mainAppState);

  const [filterTypeFilter, setFilterTypeFilter] = useState<FilterType>();

  const [loading, setLoading] = useState(true);

  const { accessToken, success } = useAuth();
  const { instance } = useMsal();

  let { filter } = useParams();

  const { t } = useTranslation();
  let navigate = useNavigate();

  useEffect(() => {
    (async () => {
      if (success) {
        setFilterTypeFilter(filter as FilterType);

        setLoading(false);
      }
    })();
  }, [accessToken, filter, success]);

  return (
    <Page>
      <Skeleton loading={loading}>
        <PageHeader
          header={
            filterTypeFilter === "AllBrands"
              ? t("resources.allBrands")
              : t("resources.allBrandsKids")
          }
          onBackClick={() => {
            navigate("/");
          }}
          rightIcon={
            <HomeIcon
              style={rightIconStyle}
              onClick={() => {
                navigate(`/`);
              }}
            />
          }
        />
        <PageContent paddingTop={40}>
          <GenerateReport
            title={t("resources.selectReportTitle")}
            filterType={filterTypeFilter}
          />
          <Stack
            style={{
              position: "fixed",
              bottom: 0,
              background: "white",
              padding: 0,
              zIndex: 99,
              width: "100%",
              textAlign: "center",
              color: "black",
            }}
          >
            <p>{appConstants()?.lastUpdateDate}</p>
          </Stack>
        </PageContent>
      </Skeleton>
    </Page>
  );
};
