import { Content } from "antd/lib/layout/layout";

type Props = {
  title: string;
  brandName: string;
  modelType: string;
  totalOrders: number;
  total: number;
  currentIndex: number;
  showInfoBlock?: boolean;
  infoBlock?: string;
};

export const ModelTotalsHeader = (props: Props) => {
  const {
    title,
    brandName,
    modelType,
    currentIndex,
    infoBlock,
    showInfoBlock,
    totalOrders,
  } = props;

  return (
    <Content
      key={`model-total-${brandName}-${currentIndex}`}
      style={{
        textAlign: "center",
        padding: "20px",
        maxWidth: 600,
        margin: "0 auto",
        paddingTop: 30,
        paddingBottom: 10,
      }}
    >
      {showInfoBlock && (
        <div
          style={{
            position: "absolute",
            marginTop: "0px",
            paddingRight: 35,
            width: "150px",
            fontSize: "12px",
            left: 20,
          }}
        >
          {infoBlock}
        </div>
      )}
      <h2 style={{ marginBottom: "0px" }}>{title}</h2>
      <h4 style={{ marginBottom: "10px" }}>
        Total orders: {totalOrders?.toLocaleString(undefined)}{" "}
      </h4>
    </Content>
  );
};
