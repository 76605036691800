import {
  InteractionStatus,
  IPublicClientApplication,
} from "@azure/msal-browser";
import {
  AuthenticatedTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
} from "@azure/msal-react";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import { RecoilRoot } from "recoil";
import { Html1Page } from "src/pages/HtmlGenerator/Html1Page";
import { Html2Page } from "src/pages/HtmlGenerator/Html2Page";
import { Html3Page } from "src/pages/HtmlGenerator/Html3Page";
import { Html4Page } from "src/pages/HtmlGenerator/Html4Page";
import { NoAccess } from "src/pages/NoAccess/NoAccess";
import { SelectBrandReport } from "src/pages/SelectBrandReport/SelectBrandReport";
import { SelectReport } from "src/pages/SelectReport/SelectReport";
import { SignIn } from "src/pages/SignIn/SignIn";
import "./App.css";
import { loginRedirectRequest } from "./authConfig";
import { BrandsCatalogue } from "./pages/BrandsCatalogue/BrandsCatalogue";
import { Welcome } from "./pages/Welcome/Welcome";

type Props = {
  pca: IPublicClientApplication;
};

function App({ pca }: Props) {
  return (
    <MsalProvider instance={pca}>
      <RecoilRoot>
        <Router>
          <Routes>
            <Route
              path="/"
              element={
                <RequireAuth>
                  <Welcome />
                </RequireAuth>
              }
            />
            <Route
              path="/brands-catalogue"
              element={
                <RequireAuth>
                  <BrandsCatalogue />
                </RequireAuth>
              }
            />
            <Route
              path="/select-brand-report"
              element={
                <RequireAuth>
                  <SelectBrandReport />
                </RequireAuth>
              }
            />
            <Route
              path="/select-report/:filter"
              element={
                <RequireAuth>
                  <SelectReport />
                </RequireAuth>
              }
            />
            <Route path="/sign-in" element={<SignIn />} />
            <Route path="/no-access" element={<NoAccess />} />
            <Route path="/generator/html1" element={<Html1Page />} />
            <Route path="/generator/html2" element={<Html2Page />} />
            <Route path="/generator/html3" element={<Html3Page />} />
            <Route path="/generator/html4" element={<Html4Page />} />
          </Routes>
        </Router>
      </RecoilRoot>
    </MsalProvider>
  );
}

// helper to define auth required routes
function RequireAuth({ children }: { children: JSX.Element }) {
  const isAuthenticated = useIsAuthenticated();
  const { instance, inProgress } = useMsal();

  if (inProgress === InteractionStatus.None && !isAuthenticated) {
    instance.loginRedirect(loginRedirectRequest);
    return <UnauthenticatedTemplate>Loading...</UnauthenticatedTemplate>;
  } else {
    return <AuthenticatedTemplate>{children}</AuthenticatedTemplate>;
  }
}

export default App;
