import { useMsal } from "@azure/msal-react";
import { PrimaryButton, Stack } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import Page from "src/core/components/Page/Page";

export const NoAccess = () => {
  const { instance } = useMsal();
  const { t } = useTranslation();

  return (
    <Page>
      <Stack horizontalAlign="center">
        <h1 style={{ fontWeight: "600", marginBottom: 0, marginTop: 0 }}>
          {t("noAccess.header")}
        </h1>
        <Stack horizontalAlign="center">
          <h3
            style={{
              fontWeight: "normal",
              paddingRight: 20,
              paddingLeft: 20,
              marginTop: 10,
            }}
          >
            {t("noAccess.intro")}
          </h3>
          <PrimaryButton
            style={{ marginTop: 20 }}
            onClick={() => instance.logout()}
          >
            {t("buttons.signOut")}
          </PrimaryButton>
        </Stack>
      </Stack>
    </Page>
  );
};
