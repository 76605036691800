let apiBaseUrl = "";
let appId = "";

if (window.location.hostname.indexOf("best") >= 0) {
  apiBaseUrl = "https://bestsellers.luxottica.com/server/api";
  appId = "82a2982c-ac6d-470f-9200-33fa238b7d4d";
} else {
  apiBaseUrl = "https://localhost:7177/api";
  appId = "658bf4eb-94e5-4335-8028-985f16178702";
}

export const API_BASE_URL = apiBaseUrl;
export const AAD_CLIENT_ID = appId;
