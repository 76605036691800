import { API_BASE_URL } from "src/constants";
import { get } from "src/core/utils/api";
import { Brand } from "src/types/common";

const createBrandService = () => {
  const fetchBrands = async (accessToken: string): Promise<Brand[]> => {
    const response = await get<Brand[]>(accessToken, API_BASE_URL + `/brands`);

    return response;
  };

  return {
    fetchBrands,
  };
};
export const brandService = createBrandService();
