import { atom } from "recoil";
import { Brand, User } from "src/types/common";

type MainAppState = {
  selectedBrand?: Brand;
};

export const mainAppState = atom<MainAppState>({
  key: "mainAppState",
  default: {
    selectedBrand: undefined,
  },
});

export const currentUserState = atom<User>({
  key: "userState",
  default: undefined,
});
