import { CollectionModelV2 } from "src/types/common";

export const getModelImageUrl = (model: CollectionModelV2) => {
  const url = `https://webapp-im.luxottica.com/${
    model.brandCode
  }/${model.modelNumber.split("/")[0].replaceAll(" ", "_")}/${model.modelNumber
    .split("/")[0]
    .replaceAll(" ", "_")}.jpg?imwidth=300`;

  return url;
};

export const getModelColorImageUrl = (model: CollectionModelV2) => {
  const url = `https://webapp-im.luxottica.com/${
    model.brandCode
  }/${model.modelNumber.split("/")[0].replaceAll(" ", "_")}/${model.modelNumber
    .split("/")[0]
    .replaceAll(" ", "_")}__${model.modelColor.replaceAll(
    "/",
    "_"
  )}.jpg?imwidth=300`;

  return url;
};
