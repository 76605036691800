import { Spinner, SpinnerSize } from "@fluentui/react";
import * as React from "react";
import { FC, PropsWithChildren } from "react";

interface Props {
  children: React.ReactNode;
  loading: boolean;
}

const Skeleton: FC<PropsWithChildren<Props>> = ({ children, loading }) => {
  return loading ? (
    <React.Fragment>
      <Spinner style={{ marginTop: 40 }} size={SpinnerSize.large} />
    </React.Fragment>
  ) : (
    <React.Fragment>{children}</React.Fragment>
  );
};

export default Skeleton;
