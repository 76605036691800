import { managementService } from "src/core/services/managementService";
import { AppConstants } from "src/types/common";

var appConstantsData: AppConstants | null = null;

export async function loadAppConstants(
  accessToken: string
): Promise<AppConstants> {
  if (!appConstantsData) {
    appConstantsData = await managementService.fetchAppConstants(accessToken);
  }

  return appConstantsData;
}

export function appConstants(): AppConstants | null {
  return appConstantsData;
}
