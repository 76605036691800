import { Image, ImageLoadState } from "@fluentui/react";
import { FC, useEffect, useState } from "react";
import avatar from "src/assets/common/avatar.png";
import { userService } from "src/core/services/userService";

interface Props {
  accessToken: string;
}

const avatarStyles = {
  width: 120,
  height: "auto",
  borderRadius: 100,
  background: "#f4f4f4",
};

const UserAvatar: FC<Props> = ({ accessToken }) => {
  const [loadError, setLoadError] = useState(accessToken === "" ? true : false);
  const [userImage, setUserImage] = useState<string>("");

  useEffect(() => {
    (async () => {
      const image = await userService.fetchUserImage(accessToken);

      if (image) {
        setUserImage(URL.createObjectURL(image));
      } else {
        setLoadError(true);
      }
    })();
  }, [accessToken]);

  return !userImage && loadError ? (
    <Image style={avatarStyles} src={avatar} />
  ) : (
    <Image
      onLoadingStateChange={(state: ImageLoadState) => {
        if (state === ImageLoadState.error) {
          setLoadError(true);
        }
      }}
      style={avatarStyles}
      src={userImage}
    />
  );
};

export default UserAvatar;
