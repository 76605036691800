export class AccessForbiddenException extends Error {
  constructor(message: string) {
    super(message);
    this.name = "AccessForbiddenException";
  }
}

export class UnauthorizedException extends Error {
  constructor(message: string) {
    super(message);
    this.name = "UnauthorizedException";
  }
}
