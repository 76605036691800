import { Col, Layout, Row, Skeleton } from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { dataService } from "src/core/services/dataService";
import { getModelColorImageUrl } from "src/core/utils/model";
import "src/GeneratorApp.css";
import { ModelGroupSubHeader } from "src/pages/HtmlGenerator/components/ModelGroupSubHeader";
import { ModelTableLine } from "src/pages/HtmlGenerator/components/ModelTableLine";
import {
  CollectionModelGroupV2,
  CollectionModelV2,
  ModelParentGroup,
} from "src/types/common";
import { ModelGroupHeader } from "./components/ModelGroupHeader";

export const Html2Page = () => {
  const [loading, setLoading] = useState(true);

  const [brandCodeFilter, setBrandCodeFilter] = useState<string>("");
  const [modelTypeFilter, setModelTypeFilter] = useState<string>("");
  const [macroRangeFilter, setMacroRangeFilter] = useState<string>("");

  const [groupsData, setGroupsData] = useState<ModelParentGroup[]>([]);

  const { search } = useLocation();

  useEffect(() => {
    (async () => {
      if (true) {
        if (search) {
          if (true) {
            const query = new URLSearchParams(search);

            const brandCode = query.get("b") ?? "";
            const modelType = query.get("t") ?? "";
            const macroRange = query.get("m") ?? "";

            const dataKey = query.get("d") ?? "";

            setBrandCodeFilter(brandCode);
            setModelTypeFilter(modelType);
            setMacroRangeFilter(macroRange);

            let response: ModelParentGroup[] | CollectionModelGroupV2[] = [];

            if (brandCode) {
              response =
                await dataService.fetchBrandCollectionModelsGroupedByModelColor(
                  dataKey,
                  brandCode,
                  modelType,
                  macroRange
                );
            } else {
              response = await dataService.fetchCollectionModelsGrouped(
                dataKey,
                "ModelAndModelColor",
                modelType,
                macroRange
              );
            }

            if (response.length > 0) {
              setGroupsData(response as ModelParentGroup[]);
            }

            setLoading(false);
          }
        }
      }
    })();
  }, [search]);

  let pageBreakCounter = -1; // -1 solves an issue of pre rendering
  let currentModelsCount = 0;
  let showLastUpdate = false;

  const checkPageBreak = (
    doPageBreak: boolean = false,
    modelsInTheNextLoop: number = 0,
    elementToInJect?: JSX.Element
  ) => {
    debugger;
    if (
      (currentModelsCount !== 0 &&
        currentModelsCount + modelsInTheNextLoop >= 14) ||
      pageBreakCounter > 5 ||
      doPageBreak
    ) {
      pageBreakCounter = 0;
      currentModelsCount = 0;

      showLastUpdate = true;

      return (
        <React.Fragment>
          <div className="pagebreak"> </div>
          {elementToInJect}
        </React.Fragment>
      );
    }
  };

  return (
    <Layout
      className={"menuBackground-0"}
      style={{
        textAlign: "center",
        minHeight: "100%",
      }}
    >
      <Skeleton active loading={loading}>
        <Content
          style={{
            margin: "0 auto",
            width: "100%",
          }}
        >
          <Row justify={"center"}>
            <Col span={24}>
              {groupsData.map((g: ModelParentGroup, groupIndex) => {
                return (
                  <React.Fragment key={`model-group-header-${groupIndex}`}>
                    {checkPageBreak(true)}
                    <ModelGroupHeader
                      key={`model-group-header-${groupIndex}`}
                      group={g}
                      groupTitle={`${g.brandName} | ${g.modelType}`}
                      currentIndex={groupIndex + 1}
                      total={g.modelGroups.length}
                      showInfoBlock={groupIndex === 0 || showLastUpdate}
                      infoBlock={g.lastUpdateDateLabel}
                    />
                    {g.modelGroups.map(
                      (group: CollectionModelGroupV2, subGroupIndex) => {
                        pageBreakCounter++;

                        if (showLastUpdate) {
                          showLastUpdate = false;
                        }

                        return (
                          <Content
                            key={`model-group-${group.modelNumber}-${subGroupIndex}`}
                            style={{
                              textAlign: "center",
                              padding: "20px",
                              paddingTop: 0,
                              maxWidth: 600,
                              margin: "0 auto",
                            }}
                          >
                            {checkPageBreak(
                              false,
                              g.modelGroups[subGroupIndex + 1]
                                ? g.modelGroups[subGroupIndex + 1].models.length
                                : 0,
                              <ModelGroupHeader
                                key={`model-group-header-${groupIndex}`}
                                group={g}
                                groupTitle={`${g.brandName} | ${g.modelType}`}
                                currentIndex={groupIndex + 1}
                                total={g.modelGroups.length}
                                showInfoBlock={true}
                                infoBlock={g.lastUpdateDateLabel}
                              />
                            )}
                            <ModelGroupSubHeader
                              subGroup={group}
                              currentIndex={subGroupIndex + 1}
                              total={g.modelGroups.length}
                            />
                            <Content
                              key={`model-colors`}
                              style={{
                                maxWidth: "900px",
                                margin: "0 auto",
                                textAlign: "left",
                              }}
                            >
                              {group.models.map(
                                (model: CollectionModelV2, index: number) => {
                                  currentModelsCount++;

                                  return (
                                    <ModelTableLine
                                      key={`model-line-${subGroupIndex}-${index}`}
                                      index={index}
                                      model={model}
                                      getModelImageUrl={getModelColorImageUrl}
                                    />
                                  );
                                }
                              )}
                            </Content>
                          </Content>
                        );
                      }
                    )}
                  </React.Fragment>
                );
              })}
            </Col>
            {groupsData.length === 0 && (
              <h2 style={{ marginTop: "35px" }}>No orders found.</h2>
            )}
          </Row>
        </Content>
      </Skeleton>
    </Layout>
  );
};
