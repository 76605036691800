import { Stack } from "@fluentui/react";
import { FC } from "react";

const PageContent: FC<{
  children: JSX.Element | JSX.Element[] | undefined;
  paddingTop?: number;
}> = ({ children, paddingTop }) => {
  return (
    <Stack
      grow
      tokens={{ childrenGap: "20" }}
      style={{ paddingTop: paddingTop ?? 75 }}
      horizontalAlign="center"
    >
      {children}
    </Stack>
  );
};

export default PageContent;
