import { Content } from "antd/lib/layout/layout";
import { CollectionModelGroupV2 } from "src/types/common";

type Props = {
  subGroup: CollectionModelGroupV2;
  total: number;
  currentIndex: number;
};

export const ModelGroupSubHeader = (props: Props) => {
  const { subGroup, total, currentIndex } = props;

  return (
    <Content
      key={`model-sub-group-${subGroup.modelNumber}-${currentIndex}`}
      style={{
        textAlign: "center",
        padding: "20px",
        maxWidth: 600,
        margin: "0 auto",
        paddingTop: 0,
      }}
    >
      <span style={{ marginBottom: "0px" }}>
        <b>{subGroup.modelNumber}</b> |{" "}
        {`${subGroup.newOrAddLabel} | ${subGroup.modelCollectionType}`} |
        Ranking: {currentIndex}/{total} | Total orders:{" "}
        {subGroup.totalSoldUnits}
      </span>
    </Content>
  );
};
