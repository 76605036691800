import { useMsal } from "@azure/msal-react";
import { Image, PrimaryButton, Stack, Text } from "@fluentui/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { loadAppConstants } from "src/appConstants";
import logo from "src/assets/logo_main.png";
import Page from "src/core/components/Page/Page";
import Skeleton from "src/core/components/Skeleton";
import UserAvatar from "src/core/components/UserAvatar";
import { useAuth } from "src/core/hooks/useAuth";
import { primaryDarkButtonStyles } from "src/core/styles/common.styles";
import { headerLogoStyle, signOutStyle } from "./Welcome.styles";

export const Welcome = () => {
  const [loading, setLoading] = useState(true);

  const { accessToken, success, graphAccessToken } = useAuth();
  const { instance } = useMsal();

  const { t } = useTranslation();
  let navigate = useNavigate();

  useEffect(() => {
    (async () => {
      if (success) {
        await loadAppConstants(accessToken);
        setLoading(false);
      }
    })();
  }, [accessToken, success]);

  return (
    <Page>
      <Skeleton loading={loading}>
        <Stack horizontalAlign="center">
          <Image style={headerLogoStyle} src={logo} />
          <h1 style={{ fontWeight: "600" }}>{t("resources.mainTitle")}</h1>
          <Stack horizontalAlign="center">
            <h2 style={{ fontWeight: "normal" }}>
              {t("resources.welcome")} {instance.getActiveAccount()?.name}
            </h2>
            <UserAvatar accessToken={graphAccessToken} />
          </Stack>
          <Stack horizontalAlign="center">
            <Text
              style={signOutStyle}
              onClick={() => {
                instance.logout();
              }}
            >
              {t("buttons.signOut")}
            </Text>
          </Stack>
        </Stack>
        <Stack
          tokens={{ childrenGap: "10" }}
          horizontalAlign="center"
          style={{ marginTop: "40px" }}
        >
          <PrimaryButton
            text={t("buttons.selectBrand")}
            styles={primaryDarkButtonStyles}
            onClick={() => {
              navigate(`/brands-catalogue`);
            }}
          />
          <PrimaryButton
            text={t("buttons.allBrands")}
            styles={primaryDarkButtonStyles}
            onClick={() => {
              navigate(`/select-report/AllBrands`);
            }}
          />
          <PrimaryButton
            text={t("buttons.allBrandsKids")}
            styles={primaryDarkButtonStyles}
            onClick={() => {
              navigate(`/select-report/AllBrandsKids`);
            }}
          />
        </Stack>
      </Skeleton>
    </Page>
  );
};
