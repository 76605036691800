import {
  Dropdown,
  IDropdownOption,
  PrimaryButton,
  Spinner,
  Stack,
  Text,
} from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "src/core/hooks/useAuth";
import { pdfService } from "src/core/services/pdfService";
import {
  dropdownStyles,
  primaryDarkButtonStyles,
} from "src/core/styles/common.styles";
import { FilterType, PdfType } from "src/types/enums";

const modelTypeOptions: IDropdownOption[] = [
  { key: "Sun", text: "Sun" },
  { key: "Optical", text: "Optical" },
  { key: "", text: "Sun + Optical" },
];

const macroRangeOptions: IDropdownOption[] = [
  { key: "Adult", text: "Adult" },
  { key: "Child", text: "Child" },
  { key: "", text: "Adult + Child" },
];

export interface Props {
  title: string;
  brandCode?: string;
  filterType?: FilterType;
}

export const GenerateReport = (props: Props) => {
  const { title, brandCode, filterType } = props;

  const [generating, setGenerating] = useState(false);

  const [generatingId1, setGeneratingId1] = useState(0);
  const [generatingId2, setGeneratingId2] = useState(0);
  const [generatingId3, setGeneratingId3] = useState(0);
  const [generatingId4, setGeneratingId4] = useState(0);

  const [modelTypeFilter, setModelTypeFilter] = useState("");
  const [macroRangeFilter, setMacroRangeFilter] = useState("");

  const { accessToken, success } = useAuth();

  const { t } = useTranslation();

  useEffect(() => {
    if (filterType === "AllBrandsKids") {
      setMacroRangeFilter("Child");
    }
  }, [filterType]);

  const generatePdf = async (pdfType: PdfType): Promise<boolean> => {
    let result = false;
    setGenerating(true);

    if (brandCode) {
      result = await pdfService.fetchAndDownloadBrandPdf(
        accessToken,
        pdfType,
        brandCode,
        modelTypeFilter,
        macroRangeFilter
      );
    } else {
      result = await pdfService.fetchAndDownloadPdf(
        accessToken,
        pdfType,
        modelTypeFilter,
        macroRangeFilter
      );
    }

    setGenerating(false);
    return result;
  };

  return (
    <Stack horizontalAlign="center">
      <Text
        as="h1"
        style={{
          color: "black",
          fontSize: 22,
          fontWeight: 600,
          textAlign: "center",
        }}
      >
        {title}
      </Text>
      <Stack tokens={{ childrenGap: "20" }}>
        <Dropdown
          key="model-type-d"
          options={modelTypeOptions}
          styles={dropdownStyles}
          selectedKey={modelTypeFilter}
          onChange={(_e, option) => {
            setModelTypeFilter((option?.key as string) ?? "");
          }}
        />
        {filterType !== "AllBrandsKids" && (
          <Dropdown
            key="macro-range-d"
            options={macroRangeOptions}
            styles={dropdownStyles}
            selectedKey={macroRangeFilter}
            onChange={(_e, option) => {
              setMacroRangeFilter((option?.key as string) ?? "");
            }}
          />
        )}
      </Stack>
      <Stack
        tokens={{ childrenGap: "15" }}
        horizontalAlign="center"
        style={{ marginTop: "40px" }}
      >
        <PrimaryButton
          disabled={generatingId1 === 1}
          styles={primaryDarkButtonStyles}
          onClick={async () => {
            setGeneratingId1(1);

            const result = await generatePdf("Model");

            setGeneratingId1(result ? 0 : 2);
          }}
        >
          {generatingId1 === 1 ? <Spinner /> : t("buttons.model")}
        </PrimaryButton>
        {generatingId1 === 2 && (
          <span style={{ color: "red", margin: 5 }}>
            {t("messages.unexpectedError")}
          </span>
        )}
        <PrimaryButton
          disabled={generatingId2 === 1}
          styles={primaryDarkButtonStyles}
          onClick={async () => {
            setGeneratingId2(1);

            const result = await generatePdf("ModelColor");

            setGeneratingId2(result ? 0 : 2);
          }}
        >
          {generatingId2 === 1 ? <Spinner /> : t("buttons.modelColor")}
        </PrimaryButton>
        {generatingId2 === 2 && (
          <span style={{ color: "red", margin: 5 }}>
            {t("messages.unexpectedError")}
          </span>
        )}
        {brandCode && (
          <React.Fragment>
            <PrimaryButton
              disabled={generatingId3 === 1}
              styles={primaryDarkButtonStyles}
              onClick={async () => {
                setGeneratingId3(1);

                const result = await generatePdf("BrandModelCollectionType");

                setGeneratingId3(result ? 0 : 2);
              }}
            >
              {generatingId3 === 1 ? (
                <Spinner />
              ) : (
                t("buttons.modelCollectionType")
              )}
            </PrimaryButton>
            {generatingId3 === 2 && (
              <span style={{ color: "red", margin: 5 }}>
                {t("messages.unexpectedError")}
              </span>
            )}
            <PrimaryButton
              disabled={generatingId4 === 1}
              styles={primaryDarkButtonStyles}
              onClick={async () => {
                setGeneratingId4(1);

                const result = await generatePdf(
                  "BrandModelColorCollectionType"
                );

                setGeneratingId4(result ? 0 : 2);
              }}
            >
              {generatingId4 === 1 ? (
                <Spinner />
              ) : (
                t("buttons.modelColorCollectionType")
              )}
            </PrimaryButton>
            {generatingId4 === 2 && (
              <span style={{ color: "red", margin: 5 }}>
                {t("messages.unexpectedError")}
              </span>
            )}
          </React.Fragment>
        )}
      </Stack>
    </Stack>
  );
};
