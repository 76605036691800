import { API_BASE_URL } from "src/constants";
import { getUnauthorized } from "src/core/utils/api";
import { CollectionModelGroupV2, ModelParentGroup } from "src/types/common";
import { GroupModelsBy } from "src/types/enums";

const createDataService = () => {
  // with passed brand

  const fetchBrandCollectionModelsGroupedByModelColor = async (
    dataKey: string,
    brandCode: string,
    modelType: string,
    macroRange: string
  ): Promise<ModelParentGroup[]> => {
    const response = await getUnauthorized<ModelParentGroup[]>(
      API_BASE_URL +
        `/data/brands/${brandCode}/grouped/color/models?modelType=${modelType}&macroRange=${macroRange}&dataKey=${dataKey}`
    );

    return response;
  };

  const fetchBrandCollectionModelsGrouped = async (
    dataKey: string,
    groupModelsBy: GroupModelsBy,
    brandCode: string,
    modelType: string,
    macroRange: string
  ): Promise<ModelParentGroup[] | CollectionModelGroupV2[]> => {
    const response = await getUnauthorized<
      ModelParentGroup[] | CollectionModelGroupV2[]
    >(
      API_BASE_URL +
        `/data/brands/${brandCode}/models/grouped/${groupModelsBy}?modelType=${modelType}&macroRange=${macroRange}&dataKey=${dataKey}`
    );

    return response;
  };

  const fetchCollectionModelsGrouped = async (
    dataKey: string,
    groupModelsBy: GroupModelsBy,
    modelType: string,
    macroRange: string
  ): Promise<ModelParentGroup[] | CollectionModelGroupV2[]> => {
    const response = await getUnauthorized<
      ModelParentGroup[] | CollectionModelGroupV2[]
    >(
      API_BASE_URL +
        `/data/models/grouped/${groupModelsBy}?modelType=${modelType}&macroRange=${macroRange}&dataKey=${dataKey}`
    );

    return response;
  };

  const fetchBrandCollectionModelTotals = async (
    dataKey: string,
    brandCode: string,
    modelType: string,
    macroRange: string
  ): Promise<CollectionModelGroupV2[]> => {
    const response = await getUnauthorized<CollectionModelGroupV2[]>(
      API_BASE_URL +
        `/data/brands/${brandCode}/grouped/modelnumber/models?modelType=${modelType}&macroRange=${macroRange}&dataKey=${dataKey}`
    );

    return response;
  };

  // with passed brand end

  return {
    fetchBrandCollectionModelTotals,
    fetchBrandCollectionModelsGroupedByModelColor,
    fetchBrandCollectionModelsGrouped,
    fetchCollectionModelsGrouped,
  };
};
export const dataService = createDataService();
