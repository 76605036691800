import { useMsal } from "@azure/msal-react";
import { Spinner, SpinnerSize, Stack } from "@fluentui/react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { loginRedirectRequest } from "src/authConfig";
import Page from "src/core/components/Page/Page";
import { useAuth } from "src/core/hooks/useAuth";

export const SignIn = () => {
  const { instance } = useMsal();
  const { success, authLoaded } = useAuth(true);
  let navigate = useNavigate();

  useEffect(() => {
    (async () => {
      if (authLoaded) {
        if (success) {
          navigate("/");
        } else {
          instance.acquireTokenRedirect(loginRedirectRequest);
        }
      }
    })();
  }, [authLoaded, instance, navigate, success]);

  return (
    <Page>
      <Stack horizontalAlign="center">
        <Spinner style={{ marginTop: 40 }} size={SpinnerSize.large} />
      </Stack>
    </Page>
  );
};
