import { API_BASE_URL } from "src/constants";
import { get } from "src/core/utils/api";
import { AppConstants } from "src/types/common";

const createManagementService = () => {
  const fetchAppConstants = async (
    accessToken: string
  ): Promise<AppConstants> => {
    const response = await get<AppConstants>(
      accessToken,
      API_BASE_URL + `/management/constants`
    );

    return response;
  };

  return {
    fetchAppConstants,
  };
};

export const managementService = createManagementService();
