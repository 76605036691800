import { CSSProperties } from "react";

export const Page: CSSProperties = {
  display: "flex",
  flexDirection: "column",
  height: "100%",
  width: "100%",
  padding: 0,
  margin: 0,
  border: 0,
  outline: 0,
  overflow: "hidden",
  paddingBottom: 30,
};
